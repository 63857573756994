import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import DuplicateAggregators from './duplicateAggregators'
import LapseSkewInput from './LapseSkewInput'
import { updateWizardStepAction } from '../../../store/pages/preCalculation'

/*
  TODO:
    1.Refactor sortColumns() to make simpler and more readable
    2. This component could be disconnected from the store since most of the props contain the store properties already
    2.FixWizard should really just be headerTypes for a better name
*/

const sortColumns = (columns, rgaColumns, fileType) => {
  const unSortedColumns = columns.map(querySelectField => {
    const order = rgaColumns.find(x => x.name === querySelectField.Name)
    if (!order) {
      return { ...querySelectField, order: querySelectField.Name }
    }
    const orderMain = fileType === 'T' && order.orderT ? order.orderT : order.order
    return { ...querySelectField, order: orderMain }
  })

  unSortedColumns.forEach(x => {
    const columnWithPrimaryKey = rgaColumns.find(w => w.name === x.Name)
    if (columnWithPrimaryKey) {
      x.isPrimaryKey = x.editKey ? x.isPrimaryKey : (columnWithPrimaryKey.isPrimaryKey || ['INVENTORY_DATE', 'UNIQUE_IDENTIFIER'].indexOf(x.Name) >= 0)
    }
    x.aggregator = !x.aggregator ? { value: 'max', label: 'max' } : x.aggregator
  })
  return unSortedColumns.sort((a, b) => a.order - b.order)
}

const DisplayFixInfo = ({ data, rgaColumns, fixWizard, formatFiles, studyType }) => {
  const dispatch = useDispatch()

  const matchCheckNameToTypes = {
    DUPLICATES_I: ['I', 'IT'],
    DUPLICATES_T: ['T'],
    LAPSE_SKEWNESS_T: ['T']
  }

  const getCurrentFormatFile = React.useCallback((checkName, types) => {
    return fixWizard && !fixWizard[checkName]
      ? formatFiles.find(file => types.includes(file.type))
      : fixWizard[checkName]
  }, [fixWizard, formatFiles])

  const determineCheckInfo = React.useCallback((checkName, types) => {
    const file = getCurrentFormatFile(checkName, types)
    const columns = sortColumns(file.columns, rgaColumns, types)

    if (!fixWizard[checkName]) {
      dispatch({
        ...updateWizardStepAction,
        payload: {
          type: checkName,
          object: { ...file, columns }
        }
      })
    }

    if (checkName === 'LAPSE_SKEWNESS_T') {
      return (
        <LapseSkewInput
          data-testid='LapseSkewnessInput'
          studyType={studyType}
          data={data}
          currentFile={file}
          rgaColumns={rgaColumns}
          fixWizard={fixWizard}
          columns={columns}
          checkName={checkName}
          type={types}
        />
      )
    }

    return (
      <DuplicateAggregators
        data-testid='duplicateAggregators'
        studyType={studyType}
        data={data}
        currentFile={file}
        rgaColumns={rgaColumns}
        fixWizard={fixWizard}
        columns={columns}
        checkName={checkName}
        type={types}
      />
    )
  }, [getCurrentFormatFile, rgaColumns, fixWizard, studyType, data, dispatch])

  const filteredTypes = matchCheckNameToTypes[data.checkName]

  if (!filteredTypes) {
    return null
  }

  return determineCheckInfo(data.checkName, filteredTypes)
}

DisplayFixInfo.propTypes = {
  data: PropTypes.any,
  rgaColumns: PropTypes.any,
  fixWizard: PropTypes.any,
  formatFiles: PropTypes.any,
  studyType: PropTypes.any
}

export default DisplayFixInfo
