import PropTypes from 'prop-types'
import React from 'react'
import ReactSelect from 'react-select'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import StopIcon from '@mui/icons-material/Stop'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import DataLakeSourceTable from './dataLakeSourceTable'
import RediFabricMappingTable from './fabricMappingTable'
import { styled } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { SingleValue, SingleValueStyles } from '../../../components/sql/SelectSingleValue'
import { addedDataSourceAction, setProjectUnsavedChangesAction } from '../../../store/pages/project/actions'

import {
  resetAction,
  fetchPackages,
  fetchDatasets
} from '../../../store/pages/dataLake'

const REQUIRED_FILE_TYPES = ['I', 'T']

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}))

const Container = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(2)
}))

const Select = styled(ReactSelect)(({ theme }) => ({
  marginLeft: theme.spacing(2)
}))

function validateFiles (files, isRedi) {
  if (isRedi) return files.some(file => file.fileType === 'I')
  return files.every(file => {
    const isRequired = !!REQUIRED_FILE_TYPES.find(fileType =>
      fileType === file.fileType
    )

    const validSelection = file.validation === 'successful'

    if (isRequired || file.dataSet) {
      return validSelection
    }

    return true
  })
}

export default function SelectSource ({
  open,
  files,
  onClose
}) {
  const dispatch = useDispatch()
  const [currentRole, setCurrentRole] = React.useState(null)
  const [currentPackage, setCurrentPackage] = React.useState(null)
  const [currentFiles, setCurrentFiles] = React.useState([])
  const dataLake = useSelector(store => store.dataLake)
  const project = useSelector(store => store.project)

  const isRedi = project.studyType === 'redi'
  const allFilesValid = validateFiles(currentFiles, isRedi)

  const roleOptions = (dataLake.roles || []).map(role => ({
    value: role,
    label: role
  }))

  const packageOptions = (dataLake.packages || []).map(pkg => ({
    value: pkg,
    label: pkg
  }))

  const datasets = (dataLake.datasets || []).map(dataset => ({
    dataSet: dataset,
    fileType: null,
    role: currentRole,
    pkg: currentPackage
  }))

  const handleRoleSelected = React.useCallback(value => {
    const role = value ? value.value : null

    setCurrentRole(value)
    setCurrentPackage(null)
    dispatch(fetchPackages(role))
  }, [dispatch])

  const handlePackageSelected = React.useCallback(value => {
    const pkg = value ? value.value : null

    setCurrentPackage(value)
    dispatch(fetchDatasets(currentRole.value, pkg))
  }, [
    currentRole,
    dispatch
  ])

  const handleClose = React.useCallback((_event, backDrop) => {
    if (backDrop === 'backdropClick') {
      return
    }

    onClose()
    dispatch(resetAction)
  }, [
    dispatch,
    onClose
  ])

  const handleSaveClick = React.useCallback(() => {
    const payload = currentFiles
      .filter(file => file.dataSet)
      .map(file => ({
        ...file,
        profileStatus: isRedi ? file.profileStatus : 'SUCCEEDED'
      }))

    dispatch({
      ...addedDataSourceAction,
      payload
    })

    onClose()
    dispatch(resetAction)
    dispatch({
      ...setProjectUnsavedChangesAction,
      payload: {
        unsavedChanges: true
      }
    })
  }, [
    isRedi,
    currentFiles,
    dispatch,
    onClose
  ])

  React.useEffect(() => {
    setCurrentFiles(files)
  }, [files])

  return (
    <Dialog
      data-testid='dialog'
      fullWidth
      open={open}
      maxWidth='lg'
      onClose={handleClose}
    >
      <DialogTitle
        data-testid='title'
        id='select-source-dialog-title'
      >
        Add data fabric files to the project
      </DialogTitle>

      <Divider />

      <DialogContent
        data-testid='content'
        style={{ overflowY: 'visible' }}
      >
        {dataLake.roles?.length > 0
          ? <Grid container>
            <Grid item xs={6}>
              <Typography variant='h6'>
                Select Role
              </Typography>

              <Select
                data-testid='select-role'
                menuPortalTarget={document.querySelector('select-source-dialog-title')}
                isClearable
                styles={SingleValueStyles}
                components={{ SingleValue }}
                options={roleOptions}
                value={currentRole}
                onChange={handleRoleSelected}
              />
            </Grid>

            <Grid item xs={6}>
              <StyledTypography variant='h6'>
                Select Package
              </StyledTypography>

              {dataLake.packages && (
                dataLake.packages.length > 0
                  ? <Select
                  data-testid='select-package'
                  isClearable
                  styles={SingleValueStyles}
                  components={{ SingleValue }}
                  options={packageOptions}
                  value={currentPackage}
                  onChange={handlePackageSelected}
                />
                  : <Container>
                  <Link
                    data-testid='link-package-help'
                    href='https://rga.service-now.com/rsp?kb_id=12c6dfbf1bc9ec10a283ececbc4bcb14&id=kb_view2'
                    target='_blank'
                  >
                    Please click here to get help with this issue.
                  </Link>
                </Container>
              )}
            </Grid>

            {isRedi
              ? (
              <RediFabricMappingTable
                files={currentFiles}
                data={datasets}
                setFiles={setCurrentFiles}
              />
                )
              : (
              <DataLakeSourceTable
                data={datasets}
                files={currentFiles}
                setFiles={setCurrentFiles}
              />
                )}
          </Grid>
          : <>
            {!dataLake.roles
              ? 'Checking Data Fabric Access'
              : 'You do not have a role assigned, please click here to get help with this issue.'
            }

            {!dataLake.roles && (
              <CircularProgress data-testid='progress' />
            )}
          </>
        }
      </DialogContent>

      <Divider />

      <DialogActions>
        <Button
          data-testid='button-cancel'
          variant='outlined'
          color='primary'
          startIcon={<StopIcon />}
          onClick={handleClose}
        >Cancel</Button>

        <Button
          data-testid='button-save'
          disabled={!allFilesValid}
          variant='contained'
          color='secondary'
          startIcon={<PlayArrowIcon />}
          onClick={handleSaveClick}
        >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

SelectSource.propTypes = {
  open: PropTypes.bool.isRequired,
  fabricMapping: PropTypes.bool,
  files: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired
}
