import { InputLabel, MenuItem, Select, Stack, styled, TextField, Tooltip } from '@mui/material'
import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '@mui/icons-material/Info'
import dayjs from 'dayjs'

const Root = styled('div')(() => ({
  width: '20%'
}))

const TOOLTIP_MESSAGE = <div>
  <u>
    Traditional Method:
  </u><br />Under the traditional method REDi will develop the IBNR factors by building a monthly claims development triangle. IBNR is distributed into the results by matching the IBNR aggregators selected as well as the corresponding years and months via the formula: IBNR_FACTOR*(CLAIM)<br /><u>Expected Basis:</u><br />The Expected Basis method uses overall A/Es, expected amounts, and IBNR factors to calculate IBNR. A monthly claim triangle is used to develop the IBNR factors just like in the traditional method.  The final IBNR is calculated and distributed into the results by matching IBNR aggregators, years and months, and using the formula: (overall a/e)*(% missing factor)*expected.<br />% missing factor = IBNR_factor/IBNR_FACTOR +1
</div>

function buildInputHandler (props) {
  return (event) => {
    const name = [props.name, event.target.name].filter(item => item).join('.')
    const value = event.target.value

    props.onChange(name, value)
  }
}

export default function Ibnr (props) {
  const handleInput = buildInputHandler(props)

  const setDateRange = () => {
    const startDate = dayjs(props.value.asOfDate).subtract(props.value.monthsPrior, 'month')
    const endDate = dayjs(props.value.asOfDate)

    return `${startDate.format('MM/YYYY')} to ${endDate.format('MM/YYYY')}`
  }

  const bases = props.allBases.filter(base => props.selectedBases.includes(base.caseNumber))

  const ibnrEnabledAggs = props.allAggregators.filter(agg => props.selectedAggregators.includes(agg.KEY_ALIAS)).filter(agg => agg.IBNR_ENABLED)
  const valTermAggs = ibnrEnabledAggs.filter(agg => props.valTermColumns.map(col => col.Name === agg.OUT_COLUMN))
  const aggNames = valTermAggs.map(agg => agg.OUT_COLUMN)

  const selectedAggs = [
    'SOURCE',
    'BENEFIT',
    'TREATY',
    'COVER_BASIS',
    ...aggNames
  ]

  const ibnrOptions = [
    {
      name: 'Traditional Method',
      caseNumber: '0'
    },
    ...bases
  ]

  return (
    <Stack direction="column" alignItems="start" gap={4}>
      <Root>
        <InputLabel>As of Date</InputLabel>
        <TextField
          data-testid='textfield-asOfDate'
          InputProps={{
            readOnly: true
          }}
          value={props.value.asOfDate}
          size="small"
          fullWidth
        />
      </Root>
      <Root>
        <InputLabel>Months Prior<small style={{ float: 'right' }}>{setDateRange()}</small></InputLabel>
        <TextField
          type='number'
          InputProps={{
            inputProps: {
              max: 360
            }
          }}
          data-testid='textfield-monthsPrior'
          size="small"
          name='monthsPrior'
          value={props.value.monthsPrior}
          fullWidth
          onChange={handleInput}
        />
      </Root>
      <Root>
        <InputLabel>Aggregators</InputLabel>
        <Select
          fullWidth
          multiple
          data-testid='ibnr-aggregators-select'
          size='small'
          onChange={handleInput}
          name='aggregators'
          value={props.value.aggregators}
          >
          {selectedAggs.map(option => (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </Root>
      <Root>
        <Stack direction="row" alignItems="center" gap={2}>

          <InputLabel >IBNR Method</InputLabel>
          <Tooltip title={TOOLTIP_MESSAGE}><InfoIcon /></Tooltip>
        </Stack>
        <Select
          fullWidth
          data-testid='ibnr-bases-select'
          size='small'
          onChange={handleInput}
          defaultValue='0'
          name='caseNumber'
          value={props.value.caseNumber}
        >
          {ibnrOptions.map(option => (
            <MenuItem key={option.name} value={option.caseNumber}>{option.name}</MenuItem>
          ))}
        </Select>
      </Root>
    </Stack>
  )
}

Ibnr.propTypes = {
  onChange: PropTypes.func,
  selectedBases: PropTypes.array,
  selectedAggregators: PropTypes.array,
  allBases: PropTypes.array,
  allAggregators: PropTypes.array,
  value: PropTypes.shape({
    aggregators: PropTypes.arrayOf(PropTypes.string),
    monthsPrior: PropTypes.number,
    caseNumber: PropTypes.string,
    asOfDate: PropTypes.string
  }),
  valTermColumns: PropTypes.array
}

Ibnr.createModel = () => {
  return {
    asOfDate: '',
    aggregators: [],
    monthsPrior: 120,
    caseNumber: '0'
  }
}

Ibnr.createSelectors = () => {
  return {
    children: {
      asOfDate: {
        ignorePristine: true,
        format: v => v || '',
        unformat: v => v || null
      },
      aggregators: {
        unsafe: true,
        clipPristine: true,
        format: v => v || '',
        unformat: v => v || null
      }
    }
  }
}
