import React from 'react'
import AccordionActions from '@mui/material/AccordionActions'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import CheckIcon from '@mui/icons-material/Check'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import InfoIcon from '@mui/icons-material/Info'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import { styled } from '@mui/material'
import PropTypes from 'prop-types'

import PrecalGrid from './precalGrid'
import Headers from './common/headers'
import DisplayFixInfo from './FixInfo'
import { includeFixAction } from '../../../store/pages/preCalculation'

const StyledGrid = styled(Grid)(({ theme }) => ({
  backgroundColor: '#DEDEDE',
  color: theme.palette.common.white,
  padding: theme.spacing(2)
}))

const AccordionSummary = styled(MuiAccordionSummary)(({
  border: '1px solid grey'
}))

const GridScroll = styled(Grid)(({
  overflow: 'visible'
}))

const GetAccordionObject = ({ data, access, studyType }) => {
  const { includeHeaders, issueAgeHeader, headers } = Headers
  const dispatch = useDispatch()
  const { fixWizard, formatFiles, rgaColumns } = useSelector(({
    preCalc: { fixWizard },
    project: { formatFiles },
    refData: { rgaColumns }
  }) => ({
    fixWizard, formatFiles, rgaColumns
  }))
  const allHeader = data.results.length > 0 ? Object.keys(data.results[0]) : []

  // TODO: WARNING: could be an unsafe mutation using reverse()
  const header = headers.indexOf(data.checkName) >= 0
    ? issueAgeHeader
    : allHeader.filter(x => {
      return includeHeaders.indexOf(x.toLowerCase()) >= 0
    }).reverse()

  const getHeaders = (results) => Object.keys(results[0])

  const newHeaders = data.checkName.includes('DUPLICATES') && studyType !== 'redi'
    ? getHeaders(data.results)
    : header

  return (<Grid item xs={12} key={data.checkName}>
    <StyledGrid item xs={12}>
      <Accordion defaultExpanded={true} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography>
            <InfoIcon />  {data.info}
            <br></br>
            <GpsFixedIcon /> {data.goal}
          </Typography>
        </AccordionSummary>
        <AccordionDetails >
          <PrecalGrid
            data={data}
            header={data.headers?.length > 0 ? data.headers : newHeaders}
            studyType={studyType} />
        </AccordionDetails>
      </Accordion>
    </StyledGrid>
    {studyType !== 'skipMap' && <StyledGrid item xs={12}>
      <Accordion defaultExpanded={true} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1d-content"
          id="panel1d-header"
        >
          <Typography> Suggested Fix </Typography>
          <FormControlLabel
            disabled={!access || data.fix || data.nonCritical}
            labelPlacement="start"
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            control={<Button
              disabled={data.fix}
              onClick={() => dispatch({
                ...includeFixAction,
                payload: { type: data.checkName, fix: true, nonReq: false }
              })}
              size='small'
              variant="outlined"
              color="primary">
              <CheckIcon />
              {(data.required) ? '  Apply Suggestion' : '  Acknowledge'}
            </Button>}
          />
        </AccordionSummary>
        <AccordionActions>
          <Divider />
        </AccordionActions>
        <AccordionDetails >
          <Grid container>
            <Grid item xs={12}>
              <pre>
                <Typography>
                  {data.fixText}
                </Typography>
              </pre>
            </Grid>
            <Divider />
            <GridScroll item xs={12}>
              <DisplayFixInfo
                data={data}
                rgaColumns={rgaColumns}
                formatFiles={formatFiles}
                fixWizard={fixWizard}
                studyType={studyType}
              />
            </GridScroll>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </StyledGrid>}
  </Grid>)
}

GetAccordionObject.propTypes = {
  data: PropTypes.object,
  access: PropTypes.bool,
  studyType: PropTypes.string
}

export default GetAccordionObject
