import PropTypes from 'prop-types'
import React from 'react'
import Select from 'react-select'
import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import MuiButton from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import LinearProgress from '@mui/material/LinearProgress'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import ErrorIcon from '@mui/icons-material/Error'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDropZone from './hooks/useFileDropZone'
import FILE_TYPES from '../../store/objects/data/fileTypes.json'
import ConfirmDialog from '../../components/dialogs/ConfirmDialog'
import MessageDialog from '../../components/dialogs/MessageDialog'
import SelectSourceDialog from './dataLake/selectSourceDialog'
import SelectEdpSourceDialog from './edp/SelectEdpSource'
import countries from '../../store/objects/data/countries.json'
import { useDispatch, useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { fetchRoles } from '../../store/pages/dataLake'
import { fetchDataProducts } from '../../store/pages/edp'
import { tableRowBackGroundColor } from '../../components/table/table.utils'
import { changedFileTypeAction } from '../../store/pages/project/actions'
import { getAccess, deleteSource, deleteSourceFabric } from '../../store/pages/project/async'

const FILE_TYPES_EMPTY = []
const FILE_TYPES_SKIPMAP = ['I', 'T', 'RH', 'TY']
const FILE_TYPES_SKIPMAP_REQUIRED = ['I', 'T']

/*

This is probably what we want long-term

const STATUS = {
  NOT_STARTED: '',
  UPLOADING: 'UPLOADING',
  PROCESSING: 'PROCESSING',
  FINISHED: 'FINISHED',
}

Then, success/failed would be represented by an icon, and determined like so:
- Is `file.status === STATUS.FINISHED`
- Is `file.errorMessage` truthy or falsy?

 */

const STATUS = {
  NOT_STARTED: '', // should default new `status` property to this when added to the list, and update `undefined` checks to use this instead
  UPLOADING: 'Uploading',
  PROFILING: 'Profiling', // should replace this with a generic "processing" status which makes sense for EDP and profiling
  PROCESSING: 'processing',
  SUCCEEDED: 'SUCCEEDED',
  SUCCEEDED_EDP: 'succeeded',
  FAILED: 'FAILED',
  FAILED_EDP: 'failed'
}

const StyledGrid = styled(Grid)(({
  backgroundColor: 'white'
}))

const StyledTableRow = styled(TableRow)(({
  height: '5'
}))

const Button = styled(MuiButton)(({
  '&.MuiButtonBase-root.MuiButton-root': {
    marginBottom: 8,
    width: '100%',
    borderWidth: 2,
    border: 'black dashed',
    boxShadow: 'none',
    padding: 20,
    backgroundColor: 'white',
    color: 'black'
  },
  '&.MuiButtonBase-root.MuiButton-root:hover': {
    color: 'none'
  }
}))

const getLabel = (type, isSkipMap) => {
  switch (type) {
    case 'I':
      return isSkipMap ? 'Val Policy' : 'Inforce'

    case 'T':
      return isSkipMap ? 'Val Terminated' : 'Termination'

    case 'RH':
      return 'Risk History'

    case 'TY':
      return 'Treaty'

    default:
      return type
  }
}

const getFileTypes = (studyType) => {
  switch (studyType) {
    case '':
    case 'redi':
      return FILE_TYPES_EMPTY

    case 'skipMap':
      return FILE_TYPES_SKIPMAP

    default:
      throw new RangeError(`Studytype invalid: ${studyType}`)
  }
}

function getFileTypeOptions (isSkipMap, missingFileTypes, project) {
  return isSkipMap
    ? FILE_TYPES.skipMap.filter(fileTypeOption =>
      missingFileTypes.includes(fileTypeOption.value)
    )
    : FILE_TYPES.default
}

function SimpleStatus ({ status }) {
  return <div data-testid='label-status'>{status}</div>
}

function ErrorStatus ({ status }) {
  return (
    <>
      <span data-testid='label-status'>Failed</span>

      <Tooltip
        data-testid='tooltip-error'
        title={status}
      >
        <ErrorIcon color='primary' />
      </Tooltip>
    </>
  )
}

function ProgressStatus ({ status, value }) {
  return (
    <>
      <div data-testid='label-status'>{status}</div>

      {typeof value === 'number'
        ? <LinearProgress
          data-testid='progress-determinate'
          color='primary'
          variant='determinate'
          value={value}
        />
        : <LinearProgress
          data-testid='progress-indeterminate'
          color='primary'
        />
      }
    </>
  )
}

function EdpStatus ({ file }) {
  if (file.profileStatus === STATUS.SUCCEEDED) {
    return <SimpleStatus status='Succeeded' />
  }

  switch (file.edpStatus) {
    case STATUS.PROCESSING:
      return <SimpleStatus status='Uploading' />

    case STATUS.SUCCEEDED_EDP:
      return <SimpleStatus status='Succeeded' />

    case STATUS.FAILED_EDP:
      return <ErrorStatus status={file.messageError} />

    default:
      return ''
  }
}

function RegularStatus ({ file }) {
  if (file.isProcessing && file.profileStatus !== STATUS.PROFILING) {
    return <ProgressStatus
      status='Uploading'
      value={file.uploadProgress}
    />
  }

  switch (file.profileStatus) {
    case STATUS.PROFILING:
      return <ProgressStatus status='Profiling' />

    case STATUS.SUCCEEDED:
      return <SimpleStatus status='Succeeded' />

    case STATUS.FAILED:
      return <ErrorStatus status={file.messageError} />

    default:
      return ''
  }
}

function Status ({ file }) {
  return file.src === 'edp'
    ? <EdpStatus file={file} />
    : <RegularStatus file={file} />
}

export default function FileList (props) {
  const dispatch = useDispatch()
  const global = useSelector(state => state.global)
  const user = useSelector(state => state.user)
  const project = useSelector(state => state.project)

  const isPOC = ['poc'].includes(process.env.REACT_APP_RGAENV)
  const isUSRegion = countries.some(country => country.value === project.country && country.region.includes('us'))
  const hasFiles = project.files.length > 0
  const isEDPFilesFailed = project.files.some(file => file.edpStatus === 'failed')

  const [open, setOpen] = React.useState(false)
  const [guid, setGuid] = React.useState('')
  const [source, setSource] = React.useState('')
  const [deletingSource, setDeletingSource] = React.useState(false)
  const [openFabricNoDelete, setOpenFabricNoDelete] = React.useState(false)

  const [openEdp, setOpenEdp] = React.useState(false)

  const access = getAccess(
    project.sharedWithIds,
    project.ownerId,
    project.studyType,
    user.role,
    user.ownerId,
    'UPLOAD'
  )

  const deleteText = `Do you want to delete ${source}?`
  const isSkipMap = project.studyType === 'skipMap'
  const fileTypes = getFileTypes(project.studyType)

  const defaultFiles = React.useMemo(() => fileTypes.map(fileType => ({
    fileType,
    dataSet: null,
    columns: null,
    validation: null,
    dataFabric: null
  })), [fileTypes])

  const missingFileTypes = fileTypes.filter(fileType =>
    !project.files.find(file =>
      file.fileType === fileType
    )
  )

  const fileTypeOptions = getFileTypeOptions(isSkipMap, missingFileTypes, project)

  const filesData = React.useMemo(() => {
    if (!project.files || !project.files.length) {
      return defaultFiles
    }

    return project.studyType !== 'redi'
      ? defaultFiles.map(defaultFile => {
        const targetFile = project.files.find(file => (
          file.fileType === defaultFile.fileType)
        )
        return targetFile || defaultFile
      })
      : project.files.map(file => ({
        ...file,
        dataSet: file.fileName
      }))
  }, [defaultFiles, project.files, project.studyType])

  const handleAddDataFabricFilesClick = React.useCallback(() => {
    dispatch(fetchRoles)
    setOpen(true)
  }, [
    dispatch,
    setOpen
  ])

  const handleAddEDPClick = React.useCallback(() => {
    dispatch(fetchDataProducts(user.sid, project.country))
    setOpenEdp(true)
  }, [dispatch, user.sid, project.country])

  const handleSourceDelete = React.useCallback(async () => {
    setDeletingSource(false)

    if (isSkipMap) {
      await dispatch(deleteSourceFabric(source))
    } else {
      await dispatch(deleteSource(guid))
    }
  }, [
    isSkipMap,
    guid,
    source,
    dispatch
  ])

  const handleDelete = React.useCallback(event => {
    const index = Number(event.currentTarget.getAttribute('data-index'))
    const file = project.files[index]

    if (
      project.studyType === 'skipMap' &&
      FILE_TYPES_SKIPMAP_REQUIRED.includes(file.type) &&
      !isEDPFilesFailed
    ) {
      setOpenFabricNoDelete(true)
    } else {
      setGuid(file.guid)
      setSource(file.fileName)
      setDeletingSource(true)
    }
  }, [project.files, project.studyType, isEDPFilesFailed])

  const handleSelectFileType = React.useCallback((option, event) => {
    dispatch({
      ...changedFileTypeAction,
      payload: {
        key: 'fileType',
        value: option.value,
        index: Number(event.name)
      }
    })
  }, [dispatch])

  const handleClose = React.useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const handleCloseEdp = React.useCallback(() => {
    setOpenEdp(false)
  }, [setOpenEdp])

  const handleCloseFabricNoDelete = React.useCallback(() => {
    setOpenFabricNoDelete(false)
  }, [setOpenFabricNoDelete])

  return (
    <Grid>
      {access && (
        <ConfirmDialog
          data-testid='dialog-delete-confirm'
          open={deletingSource}
          title='Delete Source'
          text={deleteText}
          onCancel={() => setDeletingSource(false)}
          onConfirm={handleSourceDelete}
        />
      )}

      <MessageDialog
        data-testid='dialog-fabric-cannot-delete'
        open={openFabricNoDelete}
        title='Cannot Remove Dataset'
        text='This dataset is a required field. Please use the data source dialog to select a different dataset for this field.'
        onClose={handleCloseFabricNoDelete}
      />

      <StyledGrid >
        <Grid item xs={12}>
          <SelectSourceDialog
            open={open}
            fabricMapping={props.source.fabric}
            files={filesData}
            onClose={handleClose}
          />
          <SelectEdpSourceDialog
            openEdp={openEdp}
            currentProjectFiles={project.files}
            onClose={handleCloseEdp}
            studyType={project.studyType}
          />
          {project.country && project.studyType &&
            <Grid item xs>
              <FormControlLabel
                control={
                  <Grid marginLeft={'20px'} item xs>
                    {!isSkipMap &&
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="csv"
                            onChange={props.handleChange}
                            checked={props.source.csv}
                            disabled={hasFiles || project.id}
                            data-testid="csv-checkbox"
                          />
                        }
                        label="CSV"
                      />
                    }
                    {isUSRegion &&
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="fabric"
                            onChange={props.handleChange}
                            checked={props.source.fabric}
                            data-testid="fabric-checkbox"
                            disabled={hasFiles || project.id}
                          />
                        }
                        label="Fabric"
                      />
                    }
                    {!isPOC &&
                    <FormControlLabel
                      control={
                        <Checkbox name="edp"
                          onChange={props.handleChange}
                          checked={props.source.edp}
                          disabled={hasFiles || project.id}
                        />
                      }
                      label="EDP"
                    />
                      }
                  </Grid>
                }
              />

              {(props.source.fabric) && (
                <Button
                  data-testid='fabric-button'
                  variant='contained'
                  startIcon={<PlaylistAddIcon />}
                  onClick={handleAddDataFabricFilesClick}
                >
                  ADD DATA FABRIC FILES
                </Button>
              )}
              {(props.source.edp) && (
                <Button
                  data-testid='button-open'
                  variant='contained'
                  startIcon={<PlaylistAddIcon />}
                  onClick={handleAddEDPClick}
                >
                  ADD EDP FILES
                </Button>
              )}
            </Grid>
          }
          {access && props.source.csv && <FileDropZone />}
        </Grid>

        {project.files.length > 0
          ? <Grid item xs={12}>
            <Table size='small'>
              <TableHead>
                <StyledTableRow >
                  <TableCell></TableCell>
                  <TableCell>File Name</TableCell>
                  <TableCell>File Type</TableCell>
                  <TableCell>Status</TableCell>
                </StyledTableRow>
              </TableHead>

              <TableBody>
                {project.files.map((file, index) => (
                  <TableRow
                    key={index}
                    style={tableRowBackGroundColor(index)}
                  >
                    <TableCell>
                      {access && (
                        <IconButton
                          data-testid={`button-delete-${index}`}
                          data-index={index}
                          disabled={global.isSaving}
                          size='large'
                          onClick={handleDelete}
                        >
                          <DeleteIcon fontSize='small' />
                        </IconButton>
                      )}
                    </TableCell>

                    <TableCell align='left'>
                      {file.fileName}
                    </TableCell>

                    <TableCell
                      align='left'
                      data-testid={`cell-fileType-${index}`}
                    >
                      {!file.src
                        ? (file.uploaded || global.isSaving
                            ? FILE_TYPES.default.find(option => option.value === file.fileType).label
                            : <Select
                            data-testid={`select-fileType-${index}`}
                            data-index={index}
                            index={index}
                            name={index}
                            options={fileTypeOptions}
                            onChange={handleSelectFileType}
                          />
                          )
                        : <div
                          data-testid={`row-get-label-${index}`}
                        >
                          {getLabel(file.fileType, isSkipMap)}
                        </div>
                      }
                    </TableCell>

                    <TableCell>
                      <Status file={file} />
                    </TableCell>
                  </TableRow>
                )
                )}
              </TableBody>
            </Table>
          </Grid>
          : <div data-testid='label-no-files'></div>
        }
      </StyledGrid>
    </Grid>
  )
}

FileList.propTypes = {
  source: PropTypes.object,
  handleChange: PropTypes.func
}
